import React from 'react'
import { View, H5, Image, Text } from 'dripsy'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { VenueCalendarEmbedConstants } from '@beatgig/constants/calendar-embed-constants'

import useClipboard from '@beatgig/hooks/use-clipboard'
import Card from '@beatgig/design/components/card'
import Spacer from '@beatgig/design/components/spacer'
import { APP_NAME } from '@beatgig/constants'
import { StyleSheet } from 'react-native'
import CodeCopy from '@beatgig/components/code-copy'
import LiveChatUsNote from '../../customer-service/live-chat-us-note'
import Description from '@beatgig/design/components/description'

type Props = {
  venueSlug: string
  venueName: string
} & React.ComponentProps<typeof View>

const siteName = 'Wix'

const pluginSteps = [
  {
    content: (
      <Text>
        {APP_NAME} provides a free {siteName} plugin to make it easy to add your
        calendar to your website.
      </Text>
    ),
  },
]

const highlight = { fontWeight: 'bold', color: 'cyan' }

export default function VenueEmbedWixGuide(props: Props) {
  const { sx, venueSlug, venueName } = props
  const { ref, resetStyle, isHovered } = useAnimatedHover({
    webStyleKeys: ['border-color', 'background-color'],
  })

  const [copied, clipboard] = useClipboard()

  const {
    serverUrl,
    tagName,
  } = VenueCalendarEmbedConstants.generateWixServerUrl()

  const attributes = VenueCalendarEmbedConstants.generateWixAttributes({
    slug: venueSlug,
  })

  const onCopyWixServerUrl = () => {
    if (serverUrl) {
      clipboard.set(serverUrl)
    }
  }

  const addAspectRatio = 1480 / 1158

  const tagNameAspectRatio = 570 / 1030
  const editAspectRatio = 1124 / 480

  const setButtonAspectRatio = 646 / 184
  const setPopupAspectRatio = 566 / 848
  const attributesAspectRatio = 290 / 424

  return (
    <View sx={sx}>
      <Text sx={{ textAlign: 'center' }}>
        Before starting, please make sure your website has a premium {siteName}{' '}
        subscription. {siteName} does not allow embeds for non-premium sites.
      </Text>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 1</H5>

          <Text>
            Log in to your {`site's`} {siteName} admin panel.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 2</H5>
          <Text>
            Open your {siteName} page editor. Navigate to the page where you
            want to embed your calendar.
          </Text>
          <View
            sx={{
              width: '80%',
              mx: 'auto',
              mt: 3,
            }}
          >
            <View
              sx={{
                pb: (1 / editAspectRatio) * 100 + '%',
                height: 0,
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <Image
                source={require('./edit.png')}
                sx={{
                  ...StyleSheet.absoluteFillObject,
                }}
              />
            </View>
          </View>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 3</H5>
          <Text>
            Find the spot where you want to embed your calendar, and click{' '}
            <Text sx={highlight}>Add</Text> from the panel at the left.
          </Text>
          <Spacer />
          <Text>
            Search for <Text sx={highlight}>Embed</Text> in the window that pops
            up. Next, click <Text sx={highlight}>Custom Element</Text>.
          </Text>
          <Spacer height={3} />
          <Text>
            Next, click and drag to resize the Custom Element you just created.
            Make sure it is the full width of its container. (It should meet
            with the dashed lines. {`Don't worry about resizing its height.`})
          </Text>
          <Spacer height={3} />
          <View
            sx={{
              width: '80%',
              mx: 'auto',
            }}
          >
            <View
              sx={{
                pb: (1 / addAspectRatio) * 100 + '%',
                height: 0,
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <Image
                source={require('./add.png')}
                sx={{
                  ...StyleSheet.absoluteFillObject,
                }}
              />
            </View>
          </View>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <>
        <Card sx={{ bg: 'muted' }}>
          <Card.Content>
            <H5>Step 4</H5>
            <Text>
              Hover over the newly-created Custom Element. It might be hard to
              see, but it will be on your page. Press{' '}
              <Text sx={highlight}>Choose Source</Text>.
            </Text>
            <Spacer height={3} />
            <View
              sx={{
                width: '80%',
                mx: 'auto',
              }}
            >
              <View
                sx={{
                  pb: (1 / setButtonAspectRatio) * 100 + '%',
                  height: 0,
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Image
                  source={require('./setbutton.png')}
                  sx={{
                    ...StyleSheet.absoluteFillObject,
                  }}
                />
              </View>
            </View>
            <Spacer height={3} />
            <Text>
              For your <Text sx={highlight}>{`Server URL`}</Text>, enter the URL
              below:
            </Text>
            <Spacer height={2} />
            <CodeCopy code={serverUrl} codeCardSx={resetStyle} />
            <Spacer height={3} />
            <Text>
              For the <Text sx={highlight}>Tag Name</Text>, enter this:
            </Text>
            <CodeCopy code={tagName} codeCardSx={resetStyle} />
            <Spacer height={3} />
            <Text>
              Be sure to press {`"Update"`}. It should look like this:
            </Text>
            <Spacer height={3} />
            <View
              sx={{
                width: 200,
                mx: 'auto',
              }}
            >
              <View
                sx={{
                  pb: (1 / tagNameAspectRatio) * 100 + '%',
                  height: 0,
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Image
                  source={require('./tagname.png')}
                  sx={{
                    ...StyleSheet.absoluteFillObject,
                  }}
                />
              </View>
            </View>
            <Spacer height={3} />
            <Text>Close the Element Settings popup.</Text>
          </Card.Content>
        </Card>
      </>

      <Spacer height={4} />

      <>
        <Card sx={{ bg: 'muted' }}>
          <Card.Content>
            <H5>Step 5</H5>
            <Text>
              Click <Text sx={highlight}>Set Attributes</Text>.
            </Text>
            <Spacer height={3} />
            <View
              sx={{
                width: '80%',
                mx: 'auto',
              }}
            >
              <View
                sx={{
                  pb: (1 / setButtonAspectRatio) * 100 + '%',
                  height: 0,
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Image
                  source={require('./setbutton.png')}
                  sx={{
                    ...StyleSheet.absoluteFillObject,
                  }}
                />
              </View>
            </View>
            <Spacer height={3} />
            <Text>
              Click <Text sx={highlight}>Set Attributes</Text> next.
            </Text>
            <Spacer height={3} />
            <View
              sx={{
                width: 200,
                mx: 'auto',
              }}
            >
              <View
                sx={{
                  pb: (1 / setPopupAspectRatio) * 100 + '%',
                  height: 0,
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Image
                  source={require('./setpopup.png')}
                  sx={{
                    ...StyleSheet.absoluteFillObject,
                  }}
                />
              </View>
            </View>
          </Card.Content>
        </Card>
      </>

      <Spacer height={4} />

      <>
        <Card sx={{ bg: 'muted' }}>
          <Card.Content>
            <H5>Step 6</H5>
            <Text>
              You will now create{' '}
              {attributes.length > 1
                ? `${attributes.length} attributes, shown below. Each one`
                : '1 attribute, shown below. It'}{' '}
              will have an <Text sx={highlight}>{'Attribute Name'}</Text> and a{' '}
              <Text sx={highlight}>Value</Text>.
            </Text>
            <Spacer height={3} />
            {attributes.map(({ attributeName, value }) => {
              return (
                <Card sx={{ mb: 3, bg: 'background' }} key={attributeName}>
                  <Card.Content>
                    <Description title="Attribute Name">
                      {attributeName}
                    </Description>
                    <Spacer height={3} />
                    <Description title="Value">{value}</Description>
                  </Card.Content>
                </Card>
              )
            })}

            {/* <Spacer height={4} />

            <Text>
              After entering both attributes, you should see something like
              this:
            </Text>

            <Spacer height={4} />

            <View
              sx={{
                width: 200,
                mx: 'auto',
              }}
            >
              <View
                sx={{
                  pb: (1 / attributesAspectRatio) * 100 + '%',
                  height: 0,
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Image
                  source={require('./attributes.png')}
                  sx={{
                    ...StyleSheet.absoluteFillObject,
                  }}
                />
              </View>
            </View> */}
          </Card.Content>
        </Card>
      </>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 7</H5>

          <Text>
            <Text sx={highlight}>Click Publish</Text> in the top right corner.
            You can now see your published site with your {APP_NAME} calendar!
          </Text>
          <Spacer height={3} />
          <Text>
            Please note that your calendar may not appear properly in preview
            mode or when editing. It will only show and resize as intended when
            it is published and you view it on the published page.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <LiveChatUsNote
        prepopulatedMessage={`Could you help me embed my ${
          venueName ?? 'venue'
        } calendar on my ${siteName} site?`}
      />
    </View>
  )
}
