import React, { useContext } from 'react'
import {
  NavigationRouteContext,
  NavigationContext,
} from '@react-navigation/native'
import WebModal from '@beatgig/components/web-modal'
import { H3, Text, View } from 'dripsy'
import { VenuePromptEmbedRoute } from '@beatgig/navigation/stacks/venue-stack/types'
import useVenue from '@beatgig/api-hooks/src/use-venue'
import { APP_NAME } from '@beatgig/constants'
import Ionicons from '@beatgig/design/components/ionicons'
import Spacer from '@beatgig/design/components/spacer'
import Loading from '@beatgig/design/components/loading'
import VenueEmbedCustomSiteGuide from '@beatgig/ui/venue-embed-guide/custom-site'
import useSelectedWebsiteGuide from '@beatgig/ui/venue-embed-guide/use-selected-website-guide'
import VenueEmbedWebsiteSelector from '@beatgig/ui/venue-embed-guide/website-selector'
import VenueEmbedWordpressGuide from '@beatgig/ui/venue-embed-guide/wordpress-site'
import LiveChatUsNote from '@beatgig/ui/customer-service/live-chat-us-note'
import Card from '@beatgig/design/components/card'
import Badge from '@beatgig/design/components/badge'
import VenueEmbedWixGuide from '@beatgig/ui/venue-embed-guide/wix-site'
import { useOpenModal } from '@beatgig/modals/create-modal-stack/use-open-modal'
import { VenueThemeStackParams } from '@beatgig/next-pages/dashboard/venue-theme/types'
import { useRouting, Link } from 'expo-next-react-navigation'
import Button from '@beatgig/components/button'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'

function ComingSoon({ website }: { website: 'WordPress' | 'Wix' }) {
  return (
    <Card sx={{ my: 3 }}>
      <Card.Content>
        <Badge sx={{ alignSelf: 'center' }} variant="accent" filling="faded">
          Coming soon
        </Badge>
        <Spacer />
        <Text sx={{ textAlign: 'center' }}>
          {website} embed instructions will be available soon.
        </Text>
      </Card.Content>
    </Card>
  )
}

export default function VenuePromptEmbedScreen() {
  const { closeModal } = useOpenModal<VenueThemeStackParams>()
  const navigationContext = useContext(NavigationContext)
  const reactNavigtionRouteContext = useContext(NavigationRouteContext)
  const webRouting = useRouting()

  const webParamKey: keyof VenueThemeStackParams['VenuePromptEmbedScreen'] =
    'slug'

  const venueSlug: string =
    (
      reactNavigtionRouteContext?.params as
        | undefined
        | VenuePromptEmbedRoute['params']
    )?.venueSlug || webRouting.getParam(webParamKey)
  // const { venueSlug } = useRoute<VenuePromptEmbedRoute>().params

  const { data: venue } = useVenue({
    slug: venueSlug,
  })

  const [selectedSite, websiteSelectors] = useSelectedWebsiteGuide()
  const { isWordPress, isWix, showOther, website, isOtherSites } = selectedSite

  const goBack = () => {
    if (navigationContext) {
      navigationContext.goBack()
    } else {
      closeModal('VenuePromptEmbedScreen', {
        paramsToRemove: {
          slug: false,
        },
        webOverrides: {
          replace: true,
        },
      })
    }
  }

  return (
    <WebModal
      button={<>{!venue && <Loading />}</>}
      onRequestClose={goBack}
      header
      title={venue?.name}
    >
      <H3>Embed your calendar</H3>
      <Text>
        Your customers want to know when your shows are. {APP_NAME} lets you
        embed your calendar onto your {`venue's website in minutes.`}
      </Text>
      <Spacer height={3} />
      <Text sx={{ color: 'mutedText' }}>
        Whenever you book shows, they will automatically update on your website.
      </Text>
      <Spacer height={3} />
      <Text sx={{ color: 'callout' }}>
        This will be easy. It will only take a minute.
      </Text>
      <Spacer height={3} />
      <LiveChatUsNote
        prepopulatedMessage={`Please help me embed ${
          venue?.name ?? 'my venue'
        }'s calendar on my ${
          website && !isOtherSites ? `${website} ` : ''
        }website.`}
      />
      <Spacer height={4} />
      <View sx={{ height: 1, bg: 'border' }} />
      <Spacer height={4} />
      <VenueEmbedWebsiteSelector {...selectedSite} {...websiteSelectors} />
      {showOther && (
        <VenueEmbedCustomSiteGuide
          venueSlug={venueSlug}
          venueName={venue?.name ?? ''}
        />
      )}
      {isWordPress && (
        <VenueEmbedWordpressGuide
          venueSlug={venueSlug}
          venueName={venue?.name ?? ''}
        />
      )}
      {isWix && (
        <VenueEmbedWixGuide
          venueSlug={venueSlug}
          venueName={venue?.name ?? ''}
        />
      )}
      <Card sx={{ bg: 'muted', mb: 4, mt: 3 }}>
        <Card.Content>
          <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Ionicons size={20} name="color-palette-outline" />
            <View sx={{ flex: 1, ml: 3 }}>
              <Text sx={{ fontSize: 3, fontWeight: '500' }}>
                Customize Calendar
              </Text>
            </View>
          </View>
          <Spacer height={'3'} />
          <Card>
            <Card.Content>
              <Text>
                Want to make the calendar embed match the look and feel of your
                website?
              </Text>
            </Card.Content>
          </Card>
        </Card.Content>
        <Card.Footer sx={{ justifyContent: 'flex-end' }}>
          <Link {...AuthedRoutes.venueTheme({ venueSlug })}>
            <Button
              iconRight={{
                name: 'open-outline',
              }}
              touchableProps={{ as: View }}
            >
              Customize Calendar
            </Button>
          </Link>
        </Card.Footer>
      </Card>
    </WebModal>
  )
}
