import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import { useMyVenuesQuery } from '@beatgig/gql'
import { useCallback } from 'react'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import useAuth from '@beatgig/auth-hooks/use-auth'

export default function useMyVenues(props?: { userId: string }) {
  const user = useMyAccount().data
  const auth = useAuth()
  const [{ data, error }, revalidate] = useMyVenuesQuery(
    useAdminOnBehalfOf(props?.userId || null, {
      pause: !auth.user || !User.hasOnboarded(user),
      requestPolicy: 'cache-first',
      variables: {},
    })
  )

  return {
    data: data?.myVenues,
    error,
    revalidate: useCallback(
      () =>
        revalidate({
          requestPolicy: 'cache-and-network',
        }),
      [revalidate]
    ),
  }
}
