import React from 'react'
import { View, H5, H4, Text } from 'dripsy'
import Press from '@beatgig/components/press'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { VenueCalendarEmbedConstants } from '@beatgig/constants/calendar-embed-constants'

import useClipboard from '@beatgig/hooks/use-clipboard'
import Card from '@beatgig/design/components/card'
import Spacer from '@beatgig/design/components/spacer'
import { APP_NAME } from '@beatgig/constants'
import A from '@beatgig/components/a'
import CodeCopy from '@beatgig/components/code-copy'
import LiveChatUsNote from '../../customer-service/live-chat-us-note'

type Props = {
  venueSlug: string
  venueName: string
} & React.ComponentProps<typeof View>

const siteName = 'WordPress'

const pluginSteps = [
  {
    content: (
      <Text>
        {APP_NAME} provides a free {siteName} plugin to make it easy to add your
        calendar to your website.
      </Text>
    ),
  },
]

const highlight = { fontWeight: 'bold', color: 'cyan' }

export default function VenueEmbedWordpressGuide(props: Props) {
  const { sx, venueSlug, venueName } = props
  const { ref, resetStyle, isHovered } = useAnimatedHover({
    webStyleKeys: ['border-color', 'background-color'],
  })

  const [copied, clipboard] = useClipboard()

  const embedCode = VenueCalendarEmbedConstants.generateWordpressShortcode({
    slug: venueSlug,
  })

  const onCopy = () => {
    if (embedCode) {
      clipboard.set(embedCode)
    }
  }

  return (
    <View sx={sx}>
      <Text sx={{ textAlign: 'center' }}>
        First,{' '}
        {`you'll install the ${APP_NAME} ${siteName} plugin. Next, you'll copy our shortcode onto your page.`}
      </Text>
      <Spacer height={4} />
      <View sx={{ bg: 'border', height: 1 }} />
      <Spacer height={4} />

      <H4 sx={{ textAlign: 'center' }}>First, install WordPress plugin</H4>
      <Text sx={{ textAlign: 'center' }}>
        Before embedding your calendar, we will first install the free{' '}
        {APP_NAME} {siteName} plugin on{' '}
        {venueName ? `the ${venueName}` : 'your'} website.
      </Text>
      <Spacer height={3} />
      <Text sx={{ textAlign: 'center' }}>
        If you prefer, you can{' '}
        <A sx={{ color: 'primary' }} target="_blank" href="/wp-plugin">
          download the plugin here
        </A>{' '}
        and import it . Otherwise, follow the instructions below.
      </Text>
      <Spacer height={3} />
      <Spacer height={3} />
      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 1</H5>

          <Text>
            Log in to your {`site's`} {siteName} admin panel.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 2</H5>
          <Text>
            In the left panel of the {siteName} admin panel, select{' '}
            <Text sx={highlight}>{`Plugins`}</Text>, which should open a new
            window. Next, select <Text sx={highlight}>{`Add New`}</Text>. This
            will take you to the plugin directory.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 3</H5>
          <Text>
            Use the plugin search to{' '}
            <Text sx={highlight}>search for {`"${APP_NAME}"`}</Text>.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 4</H5>
          <Text>
            Click the <Text sx={highlight}>Install</Text> button on the{' '}
            {APP_NAME} plugin.
          </Text>
          <Spacer />
          <Text>{`You'll see a loading indicator, and then some messages confirming that the ${APP_NAME} plugin was installed.`}</Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 5</H5>
          <Text>
            Look for a button that says{' '}
            <Text sx={highlight}>{`"Activate Plugin"`}</Text> or{' '}
            <Text sx={highlight}>{`"Activate"`}</Text>. If it is blue, press it
            to enable the {APP_NAME} plugin. Once the button is gray, your
            plugin is active!
          </Text>
          <Spacer height={3} />
          <Text>Continue to the next step to embed your calendar.</Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />
      <View sx={{ height: 1, bg: 'border' }} />
      <Spacer height={4} />

      <H4 sx={{ textAlign: 'center' }}>Next, embed your calendar</H4>
      <Text sx={{ textAlign: 'center' }}>
        Now that you have installed the {APP_NAME} plugin, all you have to do is
        copy and paste our shortcode onto your {siteName} site.
      </Text>

      <Spacer height={4} />

      <Press onPress={onCopy} ref={ref}>
        <Card sx={{ bg: 'muted' }}>
          <Card.Content>
            <H5>Step 1</H5>
            <Text>
              Copy the {APP_NAME} shortcode for {venueName}.
            </Text>
            <Spacer height={2} />
            <CodeCopy code={embedCode} codeCardSx={resetStyle} />
          </Card.Content>
        </Card>
      </Press>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 2</H5>
          <Text>
            Sign in to {siteName} and open the site editor. Navigate to the page
            where you want to add your calendar.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />

      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 3</H5>
          <Text>
            Press <Text sx={{ fontWeight: 'bold' }}>{'+'}</Text> icon on your
            editor to add new content to your page, and search for{' '}
            {`"Shortcode"`}. Press it when it comes up.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />
      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 4</H5>
          <Text>
            <Text sx={highlight}>Paste your shortcode</Text> that you copied
            from step 1.
          </Text>
          <Spacer />
          <Text>
            Save your page, press <Text sx={highlight}>Publish</Text> in the top
            right, and open your site.
          </Text>
        </Card.Content>
      </Card>

      <Spacer height={4} />
      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>All set!</H5>
          <Text>Your calendar should now be embedded on your website!</Text>
          <Spacer height={3} />
          <Text>
            Please note that it will not appear properly on editor or preview
            mode. The website must be published to view the calendar.
          </Text>
        </Card.Content>
      </Card>
      <Spacer height={4} />
      <LiveChatUsNote
        prepopulatedMessage={`Could you help me embed my ${
          venueName ?? 'venue'
        } calendar on my ${siteName} site?`}
      />
    </View>
  )
}
