import { embeddableWebsites } from '../embeddable-websites'
import { useState, useMemo } from 'react'

function useSelectedWebsiteGuide() {
  const [website, setWebsite] = useState<
    typeof embeddableWebsites[number]['name'] | null
  >(null)

  const handlers = useMemo(
    () => ({
      setWebsite,
      clearWebsite: () => setWebsite(null),
    }),
    []
  )

  const isSelected = !!website
  const isWordPress = website === 'WordPress'
  const isWix = website === 'Wix'
  const showOther = isSelected
  const isOtherSites = website === 'Other Sites'

  return [
    {
      website,
      showOther: showOther && !isWix && !isWordPress,
      isSelected: !!website,
      isWix,
      isWordPress,
      isOtherSites,
    },
    handlers,
  ] as const
}

export default useSelectedWebsiteGuide
