import React from 'react'
import { View, H5, Text } from 'dripsy'
import Press from '@beatgig/components/press'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { VenueCalendarEmbedConstants } from '@beatgig/constants/calendar-embed-constants'

import useClipboard from '@beatgig/hooks/use-clipboard'
import Card from '@beatgig/design/components/card'
import Spacer from '@beatgig/design/components/spacer'
import CodeCopy from '@beatgig/components/code-copy'

type Props = {
  venueSlug: string
  venueName: string
} & React.ComponentProps<typeof View>

export default function VenueEmbedCustomSiteGuide(props: Props) {
  const { sx, venueSlug, venueName } = props
  const { ref, resetStyle, isHovered } = useAnimatedHover({
    webStyleKeys: ['border-color', 'background-color'],
  })

  const [copied, clipboard] = useClipboard()

  const embedCode = VenueCalendarEmbedConstants.generateScript({
    slug: venueSlug,
  })

  const onCopy = () => {
    if (embedCode) {
      clipboard.set(embedCode)
    }
  }

  return (
    <View sx={sx}>
      <Press onPress={onCopy} ref={ref}>
        <Card sx={{ bg: 'muted' }}>
          <Card.Content>
            <H5>Step 1: Copy the embed code</H5>
            <CodeCopy code={embedCode} codeCardSx={resetStyle} />
          </Card.Content>
        </Card>
      </Press>
      <Spacer height={3} />
      <Card sx={{ bg: 'muted' }}>
        <Card.Content>
          <H5>Step 2: Paste the code</H5>
          <Text>
            You can now paste the embed code on your website, and it will show{' '}
            {venueName ? `${venueName}'s` : 'your'} calendar.
          </Text>
        </Card.Content>
      </Card>
    </View>
  )
}
