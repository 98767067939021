import React from 'react'
import { View, Text, Image } from 'dripsy'
import useSelectedWebsiteGuide from '../use-selected-website-guide'
import Card from '@beatgig/design/components/card'
import { embeddableWebsites } from '../embeddable-websites'
import Press from '@beatgig/components/press'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import useTheme from '@beatgig/theme/use-theme'

type Returns = ReturnType<typeof useSelectedWebsiteGuide>

type Props = Returns[1] & Returns[0]

export default function VenueEmbedWebsiteSelector({
  setWebsite,
  clearWebsite,
  website,
  showOther,
  isSelected,
}: Props) {
  const makeOnPressWebsite = (site: typeof website) => {
    return () => {
      setWebsite(site)
    }
  }

  const { colors } = useTheme()
  let title = 'What kind of website do you have?'

  if (website) {
    title = `${website} instructions`
  }

  return (
    <Card sx={{ mb: 4 }}>
      <Card.Content sx={{ bg: 'muted' }}>
        <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Press onPress={clearWebsite} sx={{ pr: 3 }}>
            <Ionicons
              color={colors.text}
              size={20}
              name={website ? 'chevron-back' : 'earth-outline'}
            />
          </Press>
          <View sx={{ flex: 1 }}>
            <Text sx={{ fontSize: 3, fontWeight: '500' }}>{title}</Text>
          </View>
        </View>
        <Card sx={{ display: website ? 'none' : 'flex', mt: 3 }}>
          {/* <Card.Content sx={{ bg: 'muted', flexDirection: 'row' }}>
        </Card.Content> */}
          <View>
            {embeddableWebsites.map(({ name, logo }, i) => {
              return (
                <Press onPress={makeOnPressWebsite(name)} key={name}>
                  {({ hovered }) => (
                    <View
                      sx={{
                        p: 3,
                        borderTopWidth: i ? '1' : '0',
                        borderColor: 'border',
                        bg: hovered ? `${colors.text}20` : 'transparent',
                        transitionProperty: 'background-color',
                        transitionDuration: '250ms',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Image
                        source={logo}
                        sx={{ size: 25, mr: 4 }}
                        resizeMode="contain"
                      />
                      <Text sx={{ fontSize: 3 }}>{name}</Text>
                    </View>
                  )}
                </Press>
              )
            })}
          </View>
        </Card>
      </Card.Content>
    </Card>
  )
}
