export type NumericOperators = '<' | '<=' | '=' | '!=' | '>=' | '>'
// from, operator, to
type NumericRange = [number, 'TO', number]
// operator, value
type NumericComparison = [NumericOperators, number]

export type AlgoliaNumericFilterList = {
  // if there's only one filter, then there's no need for a condition
  [facetName: string]:
    | {
        filters: (NumericComparison | NumericRange)[]
        condition: 'AND' | 'OR'
      }
    | {
        filters: [NumericComparison | NumericRange]
        /**
         * @deprecated
         * This is just here to prevent ts errors below.
         */
        condition?: never
      }
}

export type FormattedAlgoliaFacetOrNumericFilters = readonly (
  | string
  | readonly string[]
)[]

/**
 * Numeric filters for algolia.
 *
 * For more, see: https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/
 */
export const formatAlgoliaNumericFilters = (
  filterList: AlgoliaNumericFilterList = {}
): FormattedAlgoliaFacetOrNumericFilters => {
  return (
    Object.entries(filterList)
      .map(([facetName, { filters, condition }]) => {
        /**
         * Returns an array that looks like `['facetName:facetValue', ...]`
         */
        const filtersArray = filters.map((facetValue) => {
          if (facetValue.length === 2) {
            /**
             * This is a numeric comperison, such as `['>', '9000']`
             *
             * See: https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/#numeric-comparisons
             */
            const [operator, value] = facetValue
            /**
             * Returns something like `price > 1000`
             */
            return `${facetName.trim()} ${operator} ${value}`
          }

          const [fromValue, operator, toValue] = facetValue

          /**
           * Numeric range
           *
           * See: https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/#numeric-range
           *
           * Returns something like `price:5.99 TO 100`
           */
          return `${facetName.trim()}:${fromValue} ${operator} ${toValue}`
        })

        return {
          filters: filtersArray,
          condition,
        }
      })
      .reduce((prev, next) => {
        /**
         * This function returns an array of filters. Each index corresponds an AND. Nested arrays are ORs of each other.
         */
        const { filters, condition } = next

        /**
         * If there's only one item, it's an AND implicitly
         *
         * We can probably remove this, but we put it first so that we don't default to OR.
         *
         * TODO remove this? Does this even matter?
         */
        if (filters.length === 1) {
          return [...prev, ...filters]
        } else if (condition === 'OR') {
          /**
           * If this filter is an OR, that means that it gets dropped in as just an array.
           */
          return [...prev, filters]
        } else if (condition === 'AND') {
          /**
           * If this filter is an AND, then it gets spread into the larger array
           */
          return [...prev, ...filters]
        }
      }, []) ?? []
  )
}

export type AlgoliaFacetFilterList<Model extends object> = Record<
  keyof Partial<Model> | string,
  | {
      filters: string[]
      condition: 'AND' | 'OR'
    }
  // if there's only one filter, then there's no need for a condition
  | {
      filters: [string]
      condition?: never
    }
  | {
      /**
       * If you're passing array of arrays, the items inside of the nested arrays are ORs.
       *
       * The items between outer arrays are ANDs. These CANNOt be OR.
       */
      filters: (string | string[])[]
      condition: 'AND'
    }
>

// {
//   [key in keyof Model | (string & {})]?:
//     | {
//         filters: string[]
//         condition: 'AND' | 'OR'
//       }
//     // if there's only one filter, then there's no need for a condition
//     | {
//         filters: [string]
//         condition?: never
//       }
//     | {
//         /**
//          * If you're passing array of arrays, the items inside of the nested arrays are ORs.
//          *
//          * The items between outer arrays are ANDs. These CANNOt be OR.
//          */
//         filters: (string | string[])[]
//         condition: 'AND'
//       }
// }
// | {}
// | Record<
//     keyof Model | (string & {}),
//     | {
//         filters: string[]
//         condition: 'AND' | 'OR'
//       }
//     // if there's only one filter, then there's no need for a condition
//     | {
//         filters: [string]
//         condition?: never
//       }
//     | {
//         /**
//          * If you're passing array of arrays, the items inside of the nested arrays are ORs.
//          *
//          * The items between outer arrays are ANDs. These CANNOt be OR.
//          */
//         filters: (string | string[])[]
//         condition: 'AND'
//       }
//   >

const current = ['color:blue']
const example = ['food:fruit', ['food:apple', 'food:orange']]

const filters = [
  ['hip-hop', 'edm'],
  ['trap', 'house'],
]

const taggedFilters = [
  ['category_data.subgenres:hip-hop', 'category_data.subgenres:edm'],
  ['category_data.subgenres:trap', 'category_data.subgenres:house'],
]

export const formatAlgoliaFacetFilters = <Model extends object = {}>(
  filterList?: AlgoliaFacetFilterList<Model>
): FormattedAlgoliaFacetOrNumericFilters => {
  const filterLists = Object.entries(filterList || {}).map(
    ([facetName, { filters, condition }]) => {
      /**
       * Returns an array that looks like `['facetName:facetValue', ...]`,
       *
       * or, we can pass array of arrays.
       *
       * The example below gives an AND between facetName 1 & 2, but an OR between faceValue2 and facetValue3
       *
       * `['facetName1:facetValue1', ['facetName2:facetValue2', 'facetName2:facetValue3',]]`
       */
      const filtersArray = (filters as (string | string[])[])?.map(
        (facetValue: string | string[]) => {
          if (Array.isArray(facetValue)) {
            return facetValue.map((facetValue) => {
              const value = facetValue.trim()
              return `${facetName.trim()}:${
                // if the value has a space, wrap it in quotes
                // NVM, don't lol this breaks it
                // value.includes(' ') ? `\"${value}\"` : value
                value
              }`
            })
          }
          const value = facetValue.trim()
          return `${facetName.trim()}:${
            // if the value has a space, wrap it in quotes
            // NVM, don't lol this breaks it
            // value.includes(' ') ? `\"${value}\"` : value
            value
          }`
        }
      )

      return {
        filters: filtersArray,
        condition,
      }
    }
  )

  const finalFilters: (string | string[])[] = []

  filterLists.forEach(({ filters, condition }) => {
    if (filters.length === 1) {
      finalFilters.push(...filters)
    } else if (condition === 'AND') {
      finalFilters.push(...filters)
    } else if (condition === 'OR') {
      const invalidNestedArray = filters.find(
        (value) => Array.isArray(value) || typeof value !== 'string'
      )
      if (invalidNestedArray) {
        console.error(
          '[use-format-algolia-filters-error] tried to use condition OR with nested arrays. This is not possible. To use OR, you can only pass a list of strings. If you want [X or Y] AND [A or B], then pass [[X, Y], [A,B]]. This error happened with the following: ',
          `${JSON.stringify(filters, null, 2)}.`
        )
      }
      finalFilters.push(filters as string[])
    }
  })

  return finalFilters
  // filterLists.reduce((prev, next) => {
  //   /**
  //    * This function returns an array of filters. Each index corresponds an AND. Nested arrays are ORs of each other.
  //    */
  //   const { filters, condition } = next
  //   if (filters.length === 1) {
  //     return [...prev, ...filters]
  //   } else if (condition === 'OR') {
  //     /**
  //      * If this filter is an OR, that means that it gets dropped in as just an array.
  //      */
  //     return [...prev, filters]
  //   } else {
  //     /**
  //      * If this filter is an AND, then it gets spread into the larger array
  //      *
  //      * This also applies if there's no `condition`, since it's an array of only one item anyway
  //      */
  //     return [...prev, ...filters]
  // }
  // }, [])
}

const useFormatAlgoliaFilters = <Model extends object = {}>({
  facetFilters,
  numericFilters,
}: {
  facetFilters?: AlgoliaFacetFilterList<Model>
  numericFilters?: AlgoliaNumericFilterList
}) => {
  return {
    facetFilters: formatAlgoliaFacetFilters(facetFilters),
    numericFilters: formatAlgoliaNumericFilters(numericFilters),
  }
}

export default useFormatAlgoliaFilters
