import { useVenueQuery } from '@beatgig/gql'
import type { RequestPolicy } from 'urql'

export default function useVenue({
  slug,
  requestPolicy,
  id,
}: {
  requestPolicy?: RequestPolicy
} & {
  slug?: string
  id?: string
}) {
  const [{ data, error, stale, operation }, revalidate] = useVenueQuery({
    variables: slug ? { slug } : id ? { id } : {},
    pause: !(slug || id),
    requestPolicy,
  })

  return {
    operation,
    data: data?.venue,
    error,
    revalidate,
    isValidating: stale,
  }
}
