import React from 'react'
import { View } from 'dripsy'
import HoverTooltip from '../hover-tooltip'
import useClipboard from '@beatgig/hooks/use-clipboard'
import Spacer from '@beatgig/design/components/spacer'
import Card from '@beatgig/design/components/card'

import { Pre, Code } from '@expo/html-elements'
import useTheme from '@beatgig/theme/use-theme'
import Press from '../press'

type Props = {
  code: string
  codeCardSx?: React.ComponentProps<typeof View>['sx']
  // isHovered: boolean
  // copied: string | null
} & React.ComponentProps<typeof View>

export default function CodeCopy(props: Props) {
  const {
    sx = {},
    code: embedCode,
    codeCardSx,
    // isHovered = false,
    // copied = false,
  } = props

  const { fontSizes, space } = useTheme()

  const [copied, clipboard] = useClipboard()

  return (
    <Press onPress={() => clipboard.set(embedCode)}>
      {({ hovered: isHovered }) => {
        return (
          <HoverTooltip
            bg={copied ? 'primary' : 'text'}
            text={copied ? 'Copied!' : 'Click to copy.'}
            placement="top"
            anchor="left"
            borderColor={copied ? 'primary' : 'border'}
          >
            <Spacer height={2} />
            <Card
              sx={{
                ...codeCardSx,
                borderColor: copied ? 'primary' : isHovered ? 'text' : 'border',
                cursor: 'pointer',
                bg: isHovered ? 'muted' : 'background',
              }}
            >
              <Card.Content sx={{ p: 3 }}>
                <Pre style={{ margin: 0 }}>
                  <Code
                    style={{
                      color: 'white',
                      fontSize: fontSizes[0],
                    }}
                  >
                    {embedCode || 'Loading...'}
                  </Code>
                </Pre>
              </Card.Content>
            </Card>
          </HoverTooltip>
        )
      }}
      {/* {!!copied && (
        <View sx={{ mt: 3 }}>
          <Text sx={{ color: 'primary' }}>Copied to your clipboard.</Text>
        </View>
      )} */}
    </Press>
  )
}
