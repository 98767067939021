import {
  BookingRequestAlgolia,
  BookingRequestDisplay,
} from '@beatgig/api-services/booking-request'
import {
  DisplayRecommendedBooking,
  ExternalBookingOut,
} from '@beatgig/api-services'
import { DateTime } from 'luxon'
import format from 'date-fns/format'
import { useMemo } from 'react'
import { getDisplayTimezone } from '@beatgig/forms/context/timezone/get-time-zone'
import {
  BookingDisplay,
  BookingDisplayAlgolia,
  VenueBookingPublic,
} from '@beatgig/api-services/booking'
import isSameDay from 'date-fns/isSameDay'

type Booking =
  | BookingDisplay
  | null
  | BookingRequestAlgolia
  | BookingRequestDisplay
  | DisplayRecommendedBooking
  | VenueBookingPublic
  | ExternalBookingOut
  | BookingDisplayAlgolia

export const getDisplayStartTime = ({
  startTime: _startTime,
  timezone,
}: {
  startTime: string | Date
  timezone: string
}) => {
  const startTime = DateTime.fromJSDate(new Date(_startTime))
    .setZone(timezone)
    .setZone(DateTime.local().zoneName, {
      keepLocalTime: true,
    })

  const startTimeJSDate = startTime.toJSDate()

  const timeWithCustomFormat = (formatting: string) => {
    if (startTimeJSDate instanceof Date && timezone) {
      return format(startTimeJSDate, formatting)
    }
  }

  return {
    timeWithCustomFormat,
    displayDateTime: startTimeJSDate,
    luxon: startTime,
  }
}

export const getDisplayTimeRange = ({
  startTime,
  durationMins,
  timezone,
}: {
  startTime: string | Date
  timezone: string
  durationMins: number
}) => {
  const from = getDisplayStartTime({ startTime, timezone })
  const to = getDisplayStartTime({
    startTime: from.luxon.plus({ minutes: durationMins }).toISO(),
    timezone,
  })

  const startsAndEndsOnDifferentDays = !isSameDay(
    from.displayDateTime,
    to.displayDateTime
  )

  return {
    timeRange: `${from.timeWithCustomFormat(
      'hh:mm a'
    )} - ${to.timeWithCustomFormat('hh:mm a')}`,
    withCustomFormat: {
      from: from.timeWithCustomFormat,
      to: to.timeWithCustomFormat,
    },
    startsAndEndsOnDifferentDays,
  }
}

export const useDisplayStartTime: typeof getDisplayStartTime = ({
  startTime,
  timezone,
}) => {
  return useMemo(
    () => getDisplayStartTime({ startTime, timezone }),
    [startTime, timezone]
  )
}

export const getDisplayBookingStartTime = (booking?: Booking) => {
  const startTime =
    booking?.start_time &&
    booking?.venue_location?.timezone &&
    DateTime.fromJSDate(new Date(booking.start_time))
      .setZone(booking?.venue_location?.timezone)
      .setZone(DateTime.local().zoneName, {
        keepLocalTime: true,
      })
      .toJSDate()

  // const timezone = timezones.find(
  //   ({ name, group }) =>
  //     // we're either in the timezone
  //     booking?.venue_location?.timezone === name ||
  //     // or a timezone group (for example, America/Detroit is in the America/New York group)
  //     group?.includes(booking?.venue_location?.timezone ?? '')
  // )
  const timezone =
    booking?.venue_location?.timezone &&
    getDisplayTimezone(booking?.venue_location.timezone)

  const formattedStartTime =
    startTime &&
    timezone &&
    `${format(startTime, 'hh:mm aaa')} ${timezone.alternativeName} (${
      timezone.rawFormat?.split(' ')?.[0] ?? ''
    })`

  const formattedStartDate =
    startTime && timezone && format(startTime, 'MMM do, yyy')

  if (!formattedStartDate) {
    console.log('[display-booking-start-time] missing date', {
      startTime,
      timezone,
      locationZone: booking?.venue_location?.timezone,
    })
  }

  const timeWithCustomFormat = (formatting: string) => {
    if (startTime instanceof Date && timezone) {
      return format(startTime, formatting)
    }
  }

  return {
    // startTime, don't export this; make it clear it's just for text!
    formattedStartTime,
    formattedStartDate,
    timeWithCustomFormat,
  }
}

export default function useDisplayBookingStartTime(booking?: Booking) {
  return useMemo(() => getDisplayBookingStartTime(booking), [booking])
}
