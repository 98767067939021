import React, { ReactNode } from 'react'
import { View, Text } from 'dripsy'
import type { Sx as SxStyleProp } from 'dripsy'
import Loading from '../loading'

interface Props {
  title?: ReactNode | string
  children?: ReactNode | string
  className?: string
  sx?: SxStyleProp
  isText?: boolean
  textSx?: SxStyleProp
  titleSx?: SxStyleProp
  loading?: boolean | React.ComponentProps<typeof Loading>['variant']
  right?: ReactNode
}

export type DescriptionProps = Props & React.ComponentProps<typeof View>

const Description = React.memo(function Description({
  title = 'Title',
  children = '',
  sx,
  isText = typeof children === 'string',
  textSx,
  titleSx,
  loading = false,
  right = null,
  ...props
}: DescriptionProps) {
  return (
    <View sx={sx} {...props}>
      <View
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: children ? 2 : 0,
        }}
      >
        <View sx={{ flex: 1 }}>
          <Text sx={titleSx} variant="descriptionTitle">
            {title}
          </Text>
        </View>
        {!!loading && (
          <Loading variant={loading === true ? undefined : loading} />
        )}
        {!!right && right}
      </View>
      {!!children && isText && (
        <Text
          sx={{
            // fontSize: remToPixels('0.875rem'),
            // lineHeight: remToPixels('1rem'),
            color: 'text',
            // fontWeight: '500',
            ...textSx,
          }}
        >
          {children}
        </Text>
      )}
      {!!children && !isText && <>{children}</>}
    </View>
  )
})

export default Description
