import React from 'react'
import AutoLink from 'react-native-autolink'
import { Text } from 'dripsy'
import A from '../a'
import useTheme from '@beatgig/theme/use-theme'
import Color from 'color'

type Props = {
  text: string
}

const renderLink = (
  ...[text, url, _, color]: [
    ...Parameters<
      NonNullable<React.ComponentProps<typeof AutoLink>['renderLink']>
    >,
    string
  ]
) => (
  <A
    href={url.getAnchorHref()}
    target="_blank"
    sx={{
      color,
    }}
  >
    {text}
  </A>
)

export default function HyperLink({ text }: Props) {
  const theme = useTheme()
  let color: keyof typeof theme['colors']

  if (
    Color(theme.colors.primary).contrast(Color(theme.colors.background)) >= 4
  ) {
    color = 'primary'
  } else {
    color = 'blue'
  }

  return (
    <AutoLink
      text={text}
      component={Text as any}
      url
      renderLink={(...params) => renderLink(...params, color)}
    />
  )
}
