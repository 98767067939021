import Router from 'next/router'
import { useCallback } from 'react'
import { CloseModalProps, ModalParams, OpenModalProps } from './types'

export function useOpenModal<
  Query extends Record<string, ModalParams | null | undefined> = {}
>() {
  const openModal = useCallback(
    <
      RouteName extends keyof Query,
      Props extends OpenModalProps<Query, RouteName>,
      HasQuery = Query[RouteName] extends null | undefined ? false : true
    >(
      ...[modalName, props]: HasQuery extends true
        ? [modalName: RouteName, props: Props]
        : [modalName: RouteName, props: Props] | [modalName: RouteName]
    ) => {
      const { as, webOverrides, params } = props || {}
      const { query = {} } = Router
      let { pathname } = Router
      let { _modals = [] } = query as { _modals: RouteName[] }

      if (typeof _modals === 'string') _modals = [_modals]

      // push this new modal to the top of the stack
      const currentIndex = _modals.indexOf(modalName)
      if (currentIndex > -1) {
        _modals.splice(currentIndex, 1)
      }
      _modals.push(modalName)

      let shallow = true
      // we're navigating to a different Next.js page
      if (webOverrides?.pathname && Router.pathname !== webOverrides.pathname) {
        pathname = webOverrides.pathname
        shallow = false
        _modals = [modalName]
      }

      // if we're navigating to a different Next.js page,
      // then don't keep the current query params
      const { mergeQueryParams = shallow, replace = false } = webOverrides || {}

      let shouldReplace = replace
      if (replace && typeof replace === 'function') {
        shouldReplace = replace({ query: query as any })
      }

      const action = shouldReplace ? Router.replace : Router.push

      action(
        {
          pathname,
          query: {
            ...(mergeQueryParams ? query : {}),
            ...params,
            _modals: _modals as string[],
          },
        },
        as,
        {
          shallow,
        }
      )
    },
    []
  )
  const goBack = Router.back

  const closeModal = useCallback(
    <
      RouteName extends keyof Query,
      Props extends CloseModalProps<Query, RouteName>,
      HasQuery = Query[RouteName] extends null | undefined ? false : true
    >(
      ...[modalName, props]: HasQuery extends true
        ? [modalName: RouteName, props: Props]
        : [modalName: RouteName, props: Props] | [modalName: RouteName]
    ) => {
      const { pathname, query = {} } = Router
      let { _modals = [] } = query as { _modals: RouteName[] }
      if (typeof _modals === 'string') _modals = [_modals]

      const index = _modals.indexOf(modalName)
      if (props?.paramsToRemove) {
        const paramsToRemove =
          typeof props.paramsToRemove === 'function'
            ? props.paramsToRemove({ query: query as any, _modals })
            : props.paramsToRemove

        for (const param in paramsToRemove) {
          if (paramsToRemove[param]) {
            delete query[param]
          }
        }
      }
      if (index > -1) {
        _modals.splice(index, 1)
      } else {
        if (__DEV__) {
          console.error(
            '[CLOSE MODAL] tried to close ' +
              (modalName as string) +
              ' but it was not open. Maybe you should use goBack(), or create a custom function for this use case.'
          )
        }
      }

      const { replace = true } = props?.webOverrides || {}

      let shouldReplace = replace
      if (replace && typeof replace === 'function') {
        shouldReplace = replace({ query: query as any, _modals })
      }

      const action = shouldReplace ? Router.replace : Router.push

      const nextQuery = { ...query, _modals: _modals as string[] | undefined }

      if (!_modals?.length) {
        delete nextQuery._modals
      }

      action({ pathname, query: nextQuery }, props?.as, {
        shallow: true,
      })
    },
    []
  )

  return {
    openModal,
    goBack,
    closeModal,
  }
}
