import { useState, useMemo } from 'react'
import * as Clipboard from 'expo-clipboard'
import { Platform } from 'react-native'
import Analytics from '@beatgig/analytics/typez'

export default function useClipboard() {
  const [copied, setCopied] = useState<string | null>(null)

  const handlers = useMemo(
    () => ({
      set: (text: string) => {
        const copied =
          // on web, setString returns a boolean if it succeeds
          (Clipboard.setString(text) as boolean | void) || Platform.OS !== 'web'

        if (copied) {
          setCopied(text)
        }
        Analytics.track('Copy Text', { text })
      },
      clear: () => {
        setCopied(null)
      },
    }),
    []
  )

  return [copied, handlers] as const
}
