import React from 'react'
import { Text } from 'dripsy'

import Note from '@beatgig/design/components/note'

import Press from '@beatgig/components/press'
import useIntercom from '@beatgig/hooks/use-intercom'

type Props = {
  prepopulatedMessage?: string
} & React.ComponentProps<typeof Note>

const LiveChatUsNote = React.memo(function LiveChatUsNote({
  prepopulatedMessage,
  ...props
}: Props) {
  const intercom = useIntercom()

  const onPress = () => intercom.showNewMessage(prepopulatedMessage)

  return (
    <Press onPress={onPress}>
      <Note
        icon={{
          color: 'callout',
          name: 'chatbox-ellipses-outline',
        }}
        filling="faded"
        variant="callout"
        {...props}
      >
        Want help?{' '}
        <Text sx={{ fontSize: 3, color: 'callout' }}>Live chat us.</Text>
      </Note>
    </Press>
  )
})

export default LiveChatUsNote
