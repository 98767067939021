// import { CalendarEmbed } from '@beatgig/api/calendar-embed'

import { APP_URL } from './index'

export class VenueCalendarEmbedConstants {
  static attributePrefix = 'data-beatgig'
  static dataAttributes = {
    selector:
      VenueCalendarEmbedConstants.attributePrefix + '-embed="venue-calendar"',
    slug: VenueCalendarEmbedConstants.attributePrefix + '-venue-slug',
    maxWidth: VenueCalendarEmbedConstants.attributePrefix + '-max-width',
    customUrl: VenueCalendarEmbedConstants.attributePrefix + '-custom-url',
    padding: VenueCalendarEmbedConstants.attributePrefix + '-padding',
    container: VenueCalendarEmbedConstants.attributePrefix + '-container',
    width: VenueCalendarEmbedConstants.attributePrefix + '-width',
  } as const

  static makeEmbedUrl(slug: string, local = false) {
    const base = !local ? `https://${APP_URL}` : 'http://localhost:3000'
    const host = `${base}/embed/venue-calendar/${slug}`
    return host
  }

  static generateScript(
    {
      slug,
      htmlElement = 'script',
    }: { slug: string; htmlElement?: keyof HTMLElementTagNameMap },
    local = false
  ) {
    // const src = await CalendarEmbed.getEmbedScriptSource()
    const base = !local ? `https://${APP_URL}` : 'http://localhost:3000'
    const src = `${base}/iframe/venue-calendar`

    return `<${htmlElement} async ${this.dataAttributes.selector} ${this.dataAttributes.slug}="${slug}" src="${src}"></${htmlElement}>`
  }

  static generateWordpressShortcode({ slug }: { slug: string }) {
    return `[beatgig venue-slug="${slug}" embed="venue-calendar"]`
  }

  static generateWixServerUrl() {
    return {
      serverUrl: `https://${APP_URL}/iframe/wix-calendar`,
      tagName: `beatgig-calendar`,
    }
  }

  static makeScrollToMessage = () => {
    return {
      scrollToTop: true,
    } as const
  }

  static generateWixAttributes({ slug }: { slug: string }) {
    return [
      {
        attributeName: VenueCalendarEmbedConstants.dataAttributes.slug,
        value: slug,
      },
    ]
  }
}
